import React from 'react'
import { Link, useStaticQuery } from 'gatsby'
import Layout from '../../components/Layout'
import Img from "gatsby-image"
import LoadableHapi from '../../components/Hapi/LoadableHapi'



class Hapi extends React.Component {

    render() {

        return (
            <Layout title={"Hapi | Modelizame"} description={"Análisis de radiografías PA de torax utilizando deep learning. Detecta automáticamente casos de neumonía."}>
                
                <section className="section margin-top-navbar" style={{minHeight: '80vh'}}>
                    <div className="container">
                        <LoadableHapi></LoadableHapi>

                        

                                <div className="card mt-40" style={{borderRadius: '4px', width:"100%"}}>
                                    <div className="card-content">
                                        <p className="title is-4 has-text-centered">
                                            Hapi
                                        </p>
                                        <p className="mt-10" style={{marginTop:'20px'}}>
                                            Servicio web de imagen médica que analiza radiografías PA de tórax para detectar pacientes con neumonía.
                                        </p>
                                        <p  className="mt-10" style={{textAlign:'justify'}}>
                                            Se ha entrenado una red de deep learning con 15455 imágenes de pacientes sanos, y 14590 de pacientes con neumonía.
                                            Después se ha validado con 1020 imágenes de pacientes sanos y 987 imágenes de pacientes con neumonía alcanzando una sensibilidad del 94.32% y especificidad del 91.17%.
                                            Los datos utilizados para entrenar el algoritmo provienen de <a href="https://www.kaggle.com/c/rsna-pneumonia-detection-challenge" target="_blank" rel="nofollow noopener noreferrer">aquí</a>, <a href="https://www.kaggle.com/paultimothymooney/chest-xray-pneumonia" target="_blank" rel="nofollow noopener noreferrer">aquí</a> y <a href="https://github.com/BIMCV-CSUSP/BIMCV-COVID-19/tree/master/padchest-covid" target="_blank" rel="nofollow noopener noreferrer">aquí</a>.
                                        </p>
                                        <p className="mt-10" style={{textAlign:'justify'}}>
                                            El análisis se hace respetando la privacidad de los datos, ya que las radiografías se analizan dentro del propio navegador web utilizando TensorFlowJS, una versión de TensorFlow para JavaScript.
                                            Con esto se consigue ejecutar redes convolucionales utilizando el propio hardware de tu dispositivo, evitando transferir datos y distribuyendo la carga de computación.
                                        </p>
                                        <p className="mt-10" style={{textAlign:'justify'}}>
                                            Pese a los buenos resultados obtenidos en nuestro conjunto de test, hay muchos factores que podrían empeorar el rendimiento.
                                            Algunos ejemplos son los cambios de contraste debidos a la utilización de un dispositivo de adquisición diferente, o el hecho de que el paciente presente otras patologías.
                                            Además, el algoritmo está pensado para que se ejecute rápido en el navegador web, no para maximizar la tasa de acierto.
                                            Por todo ello, esta aplicación es una <strong>demo</strong>, no una herramienta que debas utilizar de manera ciega para realizar diagnóstico.
                                            Si te interesa una solución como esta para analizar tus datos, ponte en contacto con nosotros.
                                        </p>
                                    </div>
                                </div>
            
                                

                        </div>
                </section>

            </Layout>
        )
    }
}

export default Hapi